export const endpoints = {
  recaptcha: {
    verify: '/recaptcha/verify',
  },
  auth: {
    me: '/authentication/me',
    login: '/authentication/login',
    register: '/authentication/register',
    getUserByCode: (code: string) => `/authentication/user-by-code/${code}`,
    completeInvitation: '/authentication/complete-invitation',
    logout: '/authentication/logout',
    resendVerifyEmail: '/authentication/resend-verify-email',
    confirmRegister: (code: string) => `/authentication/confirm-register/${code}`,
    checkConfirmRegister: '/authentication/check-confirm-register',
    forgotPassword: '/authentication/forgot-password',
    resetPassword: '/authentication/reset-password',
  },
  dashboard: {
    chartExpenses: (environmentId: string | number) => `/dashboard/chart-expenses/${environmentId}`,
  },
  reports: {
    count: (environmentId: number) => `/reports/${environmentId}/count`,
    getAll: (environmentId: number) => `/reports/${environmentId}/all`,
    get: (id: number | string) => `/reports/${id}`,
    getUserReports: (id: number | string, environmentId: string | number) =>
      `/environments/${environmentId}/users/${id}/reports`,
    getFees: (id: number | string) => `/reports/${id}/fees`,
    accept: (id: number | string) => `/reports/${id}/accept`,
    reject: (id: number | string) => `/reports/${id}/reject`,
    delete: (id: number | string) => `/reports/${id}`,
    acceptFee: (reportId: number | string, feeId: number | string) =>
      `/reports/${reportId}/fees/${feeId}/accept`,
    rejectFee: (reportId: number | string, feeId: number | string) =>
      `/reports/${reportId}/fees/${feeId}/reject`,
    pdf: (reportId: number | string) => `/reports/${reportId}/pdf`,
    updateDocumentNumber: (feeId: number) =>
      `/reports/fee/${feeId}/update-document-number`,
  },
  users: {
    getUser: (environmentId: number | string, id: number | string) => `/users/${id}/environment/${environmentId}`,
    updateUser: (id: number | string) => `/users/${id}`,
    updateUserOverrides: (environmentId: number | string, id: number | string) =>
      `/users/${id}/environment/${environmentId}/overrides`,
    getAll: (environmentId: number | string) => `/environments/${environmentId}/users`,
    count: (environmentId: number | string) => `/environments/${environmentId}/users/count`,
    addUser: (environmentId: number | string) => `/environments/${environmentId}/users`,
    deleteUser: (environmentId: number | string, userId: number | string) =>
      `/environments/${environmentId}/users/${userId}`,
    disableUser: (environmentId: number | string, userId: number | string) =>
      `/environments/${environmentId}/users/${userId}/disable`,
     enableUser: (environmentId: number | string, userId: number | string) =>
      `/environments/${environmentId}/users/${userId}/enable`,
    resendInvitation: (environmentId: number | string, userId: number | string) =>
      `/environments/${environmentId}/users/${userId}/resend-invitation`,
    importFromExcel: (environmentId: number | string) =>
      `/environments/${environmentId}/users/import-from-excel`,
  },
  environments: {
    getEnvironment: (id: number | string) => `/environments/${id}`,
    count: '/environments/count',
    getAll: '/environments',
    create: '/environments',
    updateEnvironment: (id: number | string) => `/environments/${id}`,
    updateEnvironmentLogo: (id: number | string) => `/environments/${id}/logo`,
    delete: (id: number | string) => `/environments/${id}`,
    changeAdmin: (id: number | string) => `/environments/${id}/change-admin`,
    assignLicenses: (id: number | string) => `/environments/${id}/assign-licenses`,
  },
  currencies: {
    getCurrency: (id: number | string) => `/currencies/${id}`,
    getAll: '/currencies',
  },
  categories: {
    create: (environmentId: number | string) => `/environments/${environmentId}/categories`,
    getCategory: (id: number | string) => `/categories/${id}`,
    updateCategory: (id: number | string) => `/categories/${id}`,
    count: (environmentId: number | string) => `/environments/${environmentId}/categories/count`,
    getAll: (environmentId: number | string) => `/environments/${environmentId}/categories`,
    delete: (id: number | string) => `/categories/${id}`,
    getTags: (id: number | string) => `/categories/${id}/tags`,
  },
  tags: {
    getTag: (id: number | string) => `/tags/${id}`,
    getAll: (environmentId: number | string) => `/environments/${environmentId}/tags`,
    count: (environmentId: number | string) => `/environments/${environmentId}/tags/count`,
    create: (environmentId: number | string) => `/environments/${environmentId}/tags`,
    updateTag: (id: number | string) => `/tags/${id}`,
    delete: (id: number | string) => `/tags/${id}`,
  },
  jobOrders: {
    getJobOrder: (id: number | string) => `/job-orders/${id}`,
    count: (environmentId: number | string) => `/environments/${environmentId}/job-orders/count`,
    getAll: (environmentId: number | string) => `/environments/${environmentId}/job-orders`,
    create: (environmentId: number | string) => `/environments/${environmentId}/job-orders`,
    updateJobOrder: (id: number | string) => `/job-orders/${id}`,
    delete: (id: number | string) => `/job-orders/${id}`,
  },
  clients: {
    getClient: (id: number | string) => `/clients/${id}`,
    count: (environmentId: number | string) => `/environments/${environmentId}/clients/count`,
    getAll: (environmentId: number | string) => `/environments/${environmentId}/clients`,
    create: (environmentId: number | string) => `/environments/${environmentId}/clients`,
    updateClient: (id: number | string) => `/clients/${id}`,
    delete: (id: number | string) => `/clients/${id}`,
  },
  costCenters: {
    getCostCenter: (id: number | string) => `/cost-centers/${id}`,
    getAll: (environmentId: string | number) => `environments/${environmentId}/cost-centers`,
    count: (environmentId: string | number) => `environments/${environmentId}/cost-centers/count`,
    create: (environmentId: string | number) => `environments/${environmentId}/cost-centers`,
    updateCostCenter: (id: number | string) => `/cost-centers/${id}`,
    delete: (id: number | string) => `/cost-centers/${id}`,
  },
  subscriptions: {
    getSubscriptions: `/subscriptions`,
    getCheckoutUri: (subscriptionId: string) => `/subscriptions/${subscriptionId}/checkout-uri`,
    getChangePaymentMethodUri: (subscriptionId: string) =>
      `/subscriptions/${subscriptionId}/change-payment-method-uri`,
  },
  profile: {
    updateProfile: '/profile',
    changePassword: '/profile/change-password',
  },
};
